import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import CopyBlock from "../../components/Copy/CopyBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"

const InLaLigaPage = ({data}) => (
  <Layout>
      <SEO
        title={"Messi vs Ronaldo Goals and Stats when Ronaldo played in Spain"}
        description={`Ronaldo spent nine seasons at Real Madrid, but who scored more goals during this period? Compare Messi vs Ronaldo stats whilst Ronaldo was in La Liga.`}
        canonicalPath={`/club-stats/messi-vs-ronaldo-in-spain/`}
      />

      <h1>
        Messi vs Ronaldo in Spain <small className={`block text-gray-700 mt-2 px-4`}>Totals whilst Ronaldo was at Real Madrid (2009/10 - 2017/18)</small>
      </h1>

      

      <CompetitionBlock competition="In Spain All Comps" compTitle="All Competitions" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} subtitle="2009/10 - 2017/18" perfmsg="[comps] only" />

      <CompetitionBlock competition="In Spain League" compTitle="La Liga" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} subtitle="2009/10 - 2017/18" />

      <CompetitionBlock competition="In Spain Champions League" compTitle="Champions League" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} subtitle="2009/10 - 2017/18" />

      <CompetitionBlock competition="In Spain Copa del Rey" compTitle="Copa del Rey" subtitle="2009/10 - 2017/18" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="In Spain Supercopa de Espana" compTitle="Supercopa de Espana" subtitle="2009/10 - 2017/18" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="In Spain UEFA Super Cup" compTitle="UEFA Super Cup" subtitle="2009/10 - 2017/18" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} />

      <CompetitionBlock competition="In Spain Club World Cup" compTitle="Club World Cup" subtitle="2009/10 - 2017/18" type="inspain" mdata={data.allSheetMessiAllTimeStats.edges} rdata={data.allSheetRonaldoAllTimeStats.edges} />

      <CopyBlock>
        <h2>Messi vs Ronaldo when they played together in Spain</h2>

        <p>To understand why the Messi vs Ronaldo rivalry is so huge, you just need to take one look at their head to head stats during the time Ronaldo played for Real Madrid in Spain. In all club competitions, <strong className="text-messi">Messi scored a goal every 85 minutes</strong>, and <strong className="text-ronaldo">Ronaldo scored a goal every 84 minutes</strong>.</p>
        
        <p>For two players to have such a similar crazy scoring rate over a 9 year period is unbelievable. When you also consider that they were playing for fierce rivals in the same league, it's clear to see why the Lionel Messi vs Cristiano Ronaldo debate has transcended football.</p>

        <p>So who actually scored more? Lionel Messi scored <strong className="text-messi">472 goals in 476 games</strong> in all club competitions from 2009/10 to 2017/18, whereas Cristiano Ronaldo scored <strong className="text-ronaldo">450 goals in 438 games</strong> in all club competitions during this period when he played for Real Madrid. So Messi scored 22 more goals than Ronaldo, but Ronaldo made 38 fewer appearances than Messi.</p>

        <p>However, as mentioned previously, when you factor in minutes played their scoring ratios are almost identical.</p>

        <h3>Messi and Ronaldo La Liga stats when they played together in Spain</h3>

        <p>In La Liga, Messi scored <strong className="text-messi">329 goals in just 309 appearances</strong>, and Ronaldo scored <strong className="text-ronaldo">311 goals in 292 appearances</strong>. These kind of numbers - scoring more than a goal per game over such a long period of time - represent a phenomenal output that we very rarely see in this sport.</p>

        <p>Again, the La Liga scoring ratios are extremely similar, with <strong className="text-messi">Messi scoring a goal every 79 minutes</strong> during this period, and <strong className="text-ronaldo">Ronaldo scoring in La Liga every 81 minutes</strong>.</p>

        <p>When we factor in assists, there's slightly more daylight between the two. Messi made <strong className="text-messi">122 La Liga assists</strong> and Ronaldo made <strong className="text-ronaldo">87 assists in the league</strong>. When combined with goals, this means Messi made a direct goal contribution every 58 minutes in La Liga from 2009/10 - 2017/18, whereas Cristiano Ronaldo made a goal contribution every 63 minutes in La Liga during this period.</p>

        <p>Let's take a look at some key La Liga stats from Messi and Ronaldo's time together in Spain:</p>

        <div className="w-full overflow-x-scroll scrolling-touch text-sm">
          <table>
            <thead>
              <tr>
                <th>La Liga Stats (09/10-17/18)</th>
                <th>Messi</th>
                <th>Ronaldo</th>
              </tr>
            </thead>
            <tbody className="text-right">
              <tr>
                <th>Hat-tricks</th>
                <td>29</td>
                <td><strong className="text-highlight">34</strong></td>
              </tr>
              <tr>
                <th>Free Kick Goals</th>
                <td><strong className="text-highlight">24</strong></td>
                <td>19</td>
              </tr>
              <tr>
                <th>Penalties</th>
                <td>41/51</td>
                <td><strong className="text-highlight">61/72</strong></td>
              </tr>
              <tr>
                <th>Outside Box Goals (open play)</th>
                <td><strong className="text-highlight">31</strong></td>
                <td>23</td>
              </tr>
              <tr>
                <th>Weak Foot Goals</th>
                <td>49</td>
                <td><strong className="text-highlight">54</strong></td>
              </tr>
              <tr>
                <th>Headed Goals</th>
                <td>13</td>
                <td><strong className="text-highlight">52</strong></td>
              </tr>
              <tr>
                <th>Successful Dribbles</th>
                <td><strong className="text-highlight">1,375</strong></td>
                <td>523</td>
              </tr>
              <tr>
                <th>Key Passes</th>
                <td><strong className="text-highlight">683</strong></td>
                <td>509</td>
              </tr>
              <tr>
                <th>Average Match Rating</th>
                <td><strong className="text-highlight">8.67</strong></td>
                <td>7.94</td>
              </tr>
              <tr>
                <th>Wins</th>
                <td><strong className="text-highlight">237</strong></td>
                <td>215</td>
              </tr>
              <tr>
                <th>Draws</th>
                <td>49</td>
                <td>44</td>
              </tr>
              <tr>
                <th>Losses</th>
                <td><strong className="text-highlight">23</strong></td>
                <td>33</td>
              </tr>
              <tr>
                <th>La Liga Titles</th>
                <td><strong className="text-highlight">6</strong></td>
                <td>2</td>
              </tr>
              <tr>
                <th>La Liga Top Scorer</th>
                <td><strong className="text-highlight">5</strong></td>
                <td>3</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3>Messi and Ronaldo Champions League stats when they played together in Spain</h3>

        <p>Cristiano Ronaldo's Champions League career at Real Madrid is nothing short of phenomenal, with 4 Champions League trophies in his 9 seasons at the club, with Messi winning 2 during this timespan. He scored an outrageous <strong className="text-ronaldo">105 goals in 101 Champions League games</strong> for Real Madrid, whilst Messi scored <strong className="text-messi">83 goals in 92 Champions League games</strong> during this same period.</p>

        <p>If we look at minutes per goal, thing look a little closer, but Ronaldo still has a clear edge in terms of goalscoring; he <strong className="text-ronaldo">scored a Champions League goal every 85.9 minutes</strong> for Real Madrid, whereas Messi <strong className="text-messi">scored a Champions League goal every 94.5 minutes</strong> for Barcelona during this time period.</p>

        <p>Ronaldo also has the edge in assists, providing <strong className="text-ronaldo">27 assists</strong> in the UCL from 09/10-17/18, with Messi providing <strong className="text-messi">20 assists</strong>.</p>

        <p>Interestingly though, if we broaden the analysis to look at overall play, things look much rosier for Leo Messi. In fewer games, he made 190 key passes to Ronaldo's 173, he made a staggering 421 successful dribbles to Ronaldo's 196, and he was given the WhoScored Man of the Match in 49 out of 83 games, with Ronaldo being the best rated player in 34 out of 101 games. On a similar note, Messi's average match rating in the Champions League from 09/10-17/18 was an impressive 8.42, with Ronaldo's average rating slightly lower on 8.12.</p>

        <p>Here are some key Champions League stats for Messi and Ronaldo from 2009/10 to 2017/18:</p>

        <div className="w-full overflow-x-scroll scrolling-touch text-sm">
          <table>
            <thead>
              <tr>
                <th>Champions League Stats (09/10-17/18)</th>
                <th>Messi</th>
                <th>Ronaldo</th>
              </tr>
            </thead>
            <tbody className="text-right">
              <tr>
                <th>Hat-tricks</th>
                <td>7</td>
                <td>7</td>
              </tr>
              <tr>
                <th>Free Kick Goals</th>
                <td>3</td>
                <td><strong className="text-highlight">10</strong></td>
              </tr>
              <tr>
                <th>Penalties</th>
                <td>10/13</td>
                <td><strong className="text-highlight">14/16</strong></td>
              </tr>
              <tr>
                <th>Outside Box Goals (open play)</th>
                <td><strong className="text-highlight">10</strong></td>
                <td>5</td>
              </tr>
              <tr>
                <th>Weak Foot Goals</th>
                <td>12</td>
                <td><strong className="text-highlight">16</strong></td>
              </tr>
              <tr>
                <th>Headed Goals</th>
                <td>3</td>
                <td><strong className="text-highlight">14</strong></td>
              </tr>
              <tr>
                <th>Successful Dribbles</th>
                <td><strong className="text-highlight">421</strong></td>
                <td>196</td>
              </tr>
              <tr>
                <th>Key Passes</th>
                <td><strong className="text-highlight">190</strong></td>
                <td>173</td>
              </tr>
              <tr>
                <th>Average Match Rating</th>
                <td><strong className="text-highlight">8.42</strong></td>
                <td>8.12</td>
              </tr>
              <tr>
                <th>Wins</th>
                <td>56</td>
                <td><strong className="text-highlight">71</strong></td>
              </tr>
              <tr>
                <th>Draws</th>
                <td>21</td>
                <td>18</td>
              </tr>
              <tr>
                <th>Losses</th>
                <td>15</td>
                <td><strong className="text-highlight">12</strong></td>
              </tr>
              <tr>
                <th>Champions League Titles</th>
                <td>2</td>
                <td><strong className="text-highlight">4</strong></td>
              </tr>
              <tr>
                <th>Champions League Top Scorer</th>
                <td>4 (1 joint)</td>
                <td><strong className="text-highlight">6</strong> (1 joint)</td>
              </tr>
            </tbody>
          </table>
        </div>

      </CopyBlock>

      <LastUpdated type="Stats" />
      
    </Layout>
)
export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/in-spain/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/in-spain/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
  }
`

export default InLaLigaPage
